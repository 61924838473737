@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');


* {
  font-family: 'Almarai', sans-serif !important;
}

/* Works on Chrome, Edge, and Safari */
[data-theme=dark-blue] *::-webkit-scrollbar {
    width: 8px;
    
  }
  [data-theme=dark-blue] *::-webkit-scrollbar-track {
    background: var(--scroll-bg);
    background-color: #bdd7f1!important;
    width: 50px!important;
    border-radius: 20px;
    
  }
  [data-theme=dark-blue] *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    width: 50px!important;
    background-color: var(--element-pick-sidebar-bg-color)!important;
    
  }
  [data-theme=dark-blue] * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-color) var(--scroll-bg);
    
  }
  [data-theme=dark-blue] .awesomplete .input-with-feedback {
    border: 1px solid var(--border-color);
  }
  [data-theme=dark-blue] .form-control {
    border: 1px solid var(--border-color);
  }
  [data-theme=dark-blue] .search-bar .awesomplete input {
    background-color: var(--bg-color)
  }
  [data-theme=dark-blue] .modal-backdrop{
    background-color: #2c436b !important;
  }
  [data-theme=dark-blue] .widget .widget-head .widget-title {
    font-size: var(--text-base);
  }
  [data-theme=dark-blue] .comment-box .comment-input-header, .form-dashboard-section .section-head, .form-section .section-head, .head-title {
    font-size: var(--text-base);
    font-weight: 600;
  }
  [data-theme=dark-blue] .comment-box .comment-input-container .ql-editor {
    border: 1px solid var(--border-color);
  }
  [data-theme=dark-blue] .duration-picker .duration-input {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
  }
  [data-theme=dark-blue] .control-label {
    font-weight: 500;
  }
  [data-theme="dark"] {
    --bg-color: var(--gray-900);
    --disabled-control-bg: var(--gray-900);
    --border-color: #1c2126;
    --margin-lg : 15px;
    --text-bold: 600;
    --scroll-bg: #161a1f;
    --scroll-color: #1c2126;
  }
  
  [data-theme="light"] {
    --bg-color: #f2f5fa;
    --text-bold: 600;
    --control-bg : #fff;
    --control-bg-on-gray: #d4dcea;
    --disabled-control-bg: #f2f5fa;
    --heading-color: #333;
    --border-color:#e3e8f1;
    --avatar-frame-bg: #dde2ea;
    --margin-lg: 15px;
    --sidebar-select-color: #d4dcea;
    --awesomplete-hover-bg: #d4dcea;
    --scroll-bg: #eef1f5;
    --scroll-color: #d9dfe6;
  }
  
  
  
  
  [data-theme=dark-blue] .awesomplete > ul > li:hover, .awesomplete > ul > li[aria-selected="true"]{
    background-color: #d7e6f1;
    color: black;
  }
  [data-theme=dark-blue] .awesomplete input[type=text]{
    color: #000000;
  }
  
  [data-theme=dark-blue] [data-label="Create Workspace"]{
    background-color: #ffa00a;
    color: #fff;
  }
  
  
  /* Global CSS */
  /*     var(--hover-font-color)  */
  /* Colours */
  [data-theme=dark-blue] {
    --body-bg-color:#F5F7FF;
    --primary-color: #204E84;
    --secondary-color: #59A1ED;
    --white-color: #ffffff;
    --black-color: #000000;
    --gray-color: #888;
    --page-head-height: 50px;
    --navbar-height: 50px;
    /* default button color like action above decument create or list view or add in table  */
    --btn-default-class:#59A1ED;
    --btn-default-color-hover-class:white;
    /* primary like save or submit button color like action above decument create or list view */
    --btn-primary-bg-color:#19A66E;
    --btn-primary-bg-color-hover:#19A66E;
    
    /* navbar colors  */
    --navbar-first-color:#204E84;
    --navbar-second-color:#204E84;
    --navbar-disabled_color:#b8c6d9;
    --non-color-class:#ffffff00;
    --notification-font-color:white;
    /* title color for header text  */
    --title-color:#204E84;

    /* title color for side bar text  00A1ED*/
    --sidebar-title:#204E84;
    --sidebar-icon-color:#19A66E;  
    --sidebar-icon-selected-color:#ffffff;
    --sidebar-font-selected-color:#fff;
    --element-pick-sidebar-bg-color:#204E84;

    
    --widget-title-color:#204E84;
    --hover-bg-color:#00A1ED;
    --hover-font-color:white;
    --header-bg-color:#204E84;
    --card-pill-bg-color:#85c2df;
    --card-pill-bg-hover-color:#F4F5F6;
    --header-for-create-doc-color:#204E84;
    --header-for-create-doc-font-color:#fff;
    --input-read-only-color:#c1d6ef;
    --input-field-write:#329bcd;
    --input-field-write-font-color:black;
    --form-control:#f2f2f2;
    --form-control-write:rgba(255, 160, 10, 0.14);
    --form-grid-color:#bbbbbb;
    --grid-heading-row-color:#19A66E;
    --grid-row-select-color:#53aeeb96;
    --row-col-color:#d1d8dd;
    --grid-row-hover-color:rgba(255, 152, 0, 0.15);
    --datatable-color:#f4f4f4;
    --btn-shadow-box:#5ab6ef42;
    --btn-create-doc:#204E84;
    --btn-primary-shadow-box:#a6e7b896;
    --text-editor-bg-color:#a6cefd5e;
    --counter-color:#204E84;
    --button-secondary-dark-color:#204E84;
    --navbar-text-color:#ffffff; 
    --avatar-bg-color:#b3e4d19e;
    --avatar-font-color:#3e57aa;
    --widget-sup-title-color:#00A1ED;
    --list-view-page-header-icon:#00A1ED;
    --input-write-font-color:#61add1b0; 
    
  
  }
  
  [data-theme=dark-blue] .page-container{
    background-color: var(--body-bg-color)!important;
  }
  
  [data-theme=dark-blue] .btn-primary:active,
  [data-theme=dark-blue] .btn-secondary:focus,
  [data-theme=dark-blue] .btn-secondary:active,
  [data-theme=dark-blue] .btn-secondary:focus{
    box-shadow: none !important;
  }
  [data-theme=dark-blue] .btn-primary{
    color: var(--hover-font-color) !important;
    background-color: var(--btn-primary-bg-color)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-primary-shadow-box)!important;
  
  
  }
  [data-theme=dark-blue] .btn-primary:hover{
    background-color: var(--btn-primary-bg-color-hover) !important;
    color:var(--btn-default-color-hover-class)!important
  }
  
  
  
  [data-theme=dark-blue] .btn-secondary{
    color: var(--btn-default-class) !important;
    background-color: var(--non-color-class)!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
  }
  
  [data-theme=dark-blue] .btn-secondary:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
  }
  
  [data-theme=dark-blue] .btn-default{
    color: var(--btn-default-class) !important;
    background-color: white!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
  }
  [data-theme=dark-blue] .btn-default:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
  }
  
  
  [data-theme=dark-blue] .page-head,
  [data-theme=dark-blue] .page-head .page-head-content{
    height: var(--page-head-height);
  }
  [data-theme=dark-blue] .page-head,
  [data-theme=dark-blue] .page-head .page-head-content{
    top: var(--page-head-height);
  }
  
  
  
  /* Top Header */
  [data-theme=dark-blue] .navbar{
    background:linear-gradient(to right ,var(--navbar-first-color),var(--navbar-second-color)) !important;
    height: var(--navbar-height);
  }

  [data-theme=dark-blue] .nav.navbar-nav.d-none.d-sm-flex .disabled{
    color:var(--navbar-disabled_color)!important;
  }
  [data-theme=dark-blue] .navbar a{
    color:var(--navbar-text-color)!important;
  }
  [data-theme=dark-blue] .avatar .standard-image {
    background-color:var(--avatar-bg-color)!important;
    color: var(--avatar-font-color)!important;
  }
  
  [data-theme=dark-blue] .navbar-expand{
    background-color: var(--background-color);
  }
  [data-theme=dark-blue] #navbar-breadcrumbs > li > a::before{
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    margin-left: .3em;
    display: inline-block;
  
    font-size: 24px;
    transition: 0.2s;
    position: relative;
    top: 3px;
    content: "\f105";
    margin-left: 10px;
    /* color: var(--white-color); */
  }
  
  [data-theme=dark-blue] .nav-link.notifications-icon.text-muted:hover{
    fill: var(--non-color-class)!important;
    stroke: var(--hover-font-color)!important;
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    background-color: var(--hover-bg-color);
    border-radius: 50%;
  }

  
  [data-theme=dark-blue] #navbar-breadcrumbs a{
    color: var(--white-color);
    text-decoration: none;
  }
  [data-theme=dark-blue] header .form-control{
    color: var(--white-color);
  }
  [data-theme=dark-blue] header .form-control:focus{
    border: 0;
  }
  [data-theme=dark-blue] .search-bar .awesomplete input{
    height: 32px;
  }
  
  [data-theme=dark-blue] header ul#navbar-breadcrumbs{
    margin-top: -8px;
  }
  [data-theme=dark-blue] header .form-control:focus{
    background-color: var(--white-color) !important;
  }
  
  
  
  /* Sidebar */
  /* header in sidebar  */
  
  [data-theme=dark-blue] .flex .title-text{
    color: var(--title-color);
  }
  [data-theme=dark-blue] .page-title .sidebar-toggle-btn  {
    --icon-stroke: var(--sidebar-title);
  }
  [data-theme=dark-blue] .standard-sidebar-section.nested-container .standard-sidebar-label{
    color:var(--title-color)!important;
    stroke:var(--title-color)!important;
    --icon-stroke:var(--title-color)!important;
  }
  [data-theme=dark-blue] .standard-sidebar-section.nested-container .standard-sidebar-label use{
    color:var(--title-color)!important;
    stroke:var(--title-color)!important;
    --icon-stroke:var(--title-color)!important;
  }

  /* element in sidebar */
  /* element when selected */
  [data-theme=dark-blue] .standard-sidebar-item use{
    --icon-stroke: var(--sidebar-icon-color);
  
  }
  [data-theme=dark-blue] .standard-sidebar-item.selected{
    background:var(--element-pick-sidebar-bg-color) !important;
  }
  [data-theme=dark-blue] .standard-sidebar-item.selected use{
    --icon-stroke: var(--sidebar-icon-selected-color);
  
  }
  [data-theme=dark-blue] .standard-sidebar-item.selected a{
    color:var(--sidebar-font-selected-color);
  }
  [data-theme=dark-blue] .standard-sidebar-item:hover{
    background:var(--hover-bg-color) !important;
  }
  [data-theme=dark-blue] .standard-sidebar-item:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color);
  
  }
  [data-theme=dark-blue] .standard-sidebar-item:hover a{
    color:var(--sidebar-font-selected-color);
  }
  [data-theme=dark-blue] .layout-side-section .sidebar-label{
    color: var(--title-color);
  }
  [data-theme=dark-blue] .sidebar-label svg,
  [data-theme=dark-blue] .layout-side-section .sidebar-label .icon{
    stroke: var(--primary-color);
  }
  [data-theme=dark-blue] .list-sidebar-button{
    background-color: var(--non-color-class) !important;
    box-shadow: none !important;
  }
  [data-theme=dark-blue] .list-sidebar-button:active,
  [data-theme=dark-blue] .list-sidebar-button:focus{
    text-decoration: none !important;
    box-shadow: none !important;
  }
  
  /* Main Section Layout */
  /* wedgit link (card content) */
  [data-theme=dark-blue] .onboarding-steps-wrapper a:hover{
    background-color: var(--hover-bg-color)!important;
    color: var(--hover-font-color)!important;
  }
  [data-theme=dark-blue] .onboarding-steps-wrapper a.active{
    background-color: var(--element-pick-sidebar-bg-color)!important;
    color: var(--hover-font-color)!important;
  }
  [data-theme=dark-blue] .widget-body .link-item:hover{
    color: var(--sidebar-font-selected-color);
    background: var(--hover-bg-color);
    
  }
  [data-theme=dark-blue] .indicator-pill.gray,
  [data-theme=dark-blue] .indicator-pill-right.gray,
  [data-theme=dark-blue] .indicator-pill-round.gray {
    background: var(--card-pill-bg-color);
    color: var(--title-color);
  }
  
  [data-theme=dark-blue] .indicator-pill.gray::before{
    background:var(--title-color)
  }
  [data-theme=dark-blue] .indicator-pill.yellow{
    background: var(--card-pill-bg-color) !important;
    color: 0f7ad5!important;
    --icon-stroke: var(--white-color)!important;
  }
  [data-theme=dark-blue] .indicator-pill.yellow::before{
    background:var(--title-color)
  }
  [data-theme=dark-blue] .widget-body .link-item:hover .indicator-pill.no-margin.yellow{
    background: var(--card-pill-bg-hover-color) !important;
    color: var(--sidebar-font-selected-color)!important;
    fill:var(--sidebar-font-selected-color)!important;
    stroke: var(--sidebar-font-selected-color)!important;
    --icon-stroke: var(--sidebar-font-selected-color)!important;
  }
  
  /* main body for section  */
  
  [data-theme=dark-blue] .layout-main-section-wrapper .layout-main-section{
    border:1px solid var(--hover-bg-color);
    border-radius: 10px;
  }
  [data-theme=dark-blue] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title{
    color: var(--widget-sup-title-color);
  }
  [data-theme=dark-blue] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title use{
    --icon-stroke: var(--widget-sup-title-color);
  }
  
  
  
  [data-theme=dark-blue] .widget.shortcut-widget-box:hover span {
    color :var(--widget-sup-title-color)!important;
  
  }
  [data-theme=dark-blue] .widget.shortcut-widget-box:hover use {
    --icon-stroke :var(--widget-sup-title-color)!important;
    stroke: var(--widget-sup-title-color)!important;
  
  }
  
  [data-theme=dark-blue] .h4{
    color: var(--title-color);
  }
  
  
  /* list view document page */
  /* header action bar */
  
  
  
  /* header list document section action bar and filter  */
  [data-theme=dark-blue] .input-xs {
    height: 24px!important;
    padding:5px!important
  }
  [data-theme=dark-blue] .page-form.flex {
    background-color: var(--header-bg-color);
  }
  [data-theme=dark-blue] .btn-new-doc {
    background-color: var(--header-bg-color)!important;
    box-shadow:  0px 0px 0px 1px  var(--btn-create-doc)!important;
  }
  [data-theme=dark-blue] .page-actions use {
    --icon-stroke:var(--title-color)!important;
    
  }
  [data-theme=dark-blue] .page-actions button:hover use{
    --icon-stroke:var(--sidebar-icon-selected-color)!important;
  
  }
  [data-theme=dark-blue] .filter-section use {
    --icon-stroke:var(--list-view-page-header-icon)!important;
    
  }
  
  [data-theme=dark-blue] .filter-section button:hover use{
    --icon-stroke:white!important;
  
  }
  
  
  
  
  
  /* create new document */
  
  [data-theme=dark-blue] .section-head {
    color:var(--title-color)!important;
  }
  [data-theme=dark-blue] .form-page .form-tabs-list {
    background-color: var(--header-for-create-doc-color)!important;
    color:var(--hover-font-color)!important;
  
  }
  [data-theme=dark-blue] .form-page .form-tabs-list a {
    color:var(--header-for-create-doc-font-color)!important;
  }
  [data-theme=dark-blue] .grid-heading-row{
    border-bottom:none!important;
    background-color:var(--grid-heading-row-color)!important ;
    color: white!important;
  }
  [data-theme=dark-blue] .input-with-feedback.form-control{
    background-color: var(--input-write-font-color)!important;
    color: var(--black-colorr)!important;
  }
  [data-theme=dark-blue] .form-group .control-input-wrapper .control-input .input-with-feedback.form-control::-moz-placeholder{
    color: white!important;
  }
  [data-theme=dark-blue] .form-group .control-input-wrapper .control-input .input-with-feedback.form-control::placeholder{
    color: white!important;
  }
  [data-theme=dark-blue] .like-disabled-input{
    background-color: var(--input-read-only-color)!important;
  }
  
  [data-theme=dark-blue] .standard-filter-section select{
    background-color: var(--title-color)!important;
    
  }
  
  [data-theme=dark-blue] .input-with-feedback.form-control:focus{
    background-color: var(--card-pill-bg-color)!important;
  
    border:1px solid var(--input-field-write)!important ;
    /* border-radius: 10px; */
    color: var(--title-color);
    
  }
  
  
  /* Input Fields */
  
  [data-theme=dark-blue] .form-control{
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-color: #f2f2f2 !important;
  }
  [data-theme=dark-blue] .form-control:focus{
    outline: 0;
    border: 1px solid var(--primary-color);
    box-shadow: none;
    background: var(--form-control-write) !important;
  }
 
  [data-theme=dark-blue] span.notifications-seen use,
  [data-theme=dark-blue] symbol#icon-small-down{
    fill: var(--non-color-class)!important;
    stroke: var(--hover-font-color)!important;
    --icon-stroke: var(--notification-font-color)!important;
  
  
  }
  
  
  /* Table */
  
  [data-theme=dark-blue] .form-grid{
    border-radius: 0;
    border: 1px solid var(--form-grid-color);
  }
  [data-theme=dark-blue] .form-grid .grid-heading-row{
    background-color: var(--grid-heading-row-color);
  }
  [data-theme=dark-blue] .grid-heading-row{
    border-bottom: 1px solid var(--primary-color);
  }
  [data-theme=dark-blue] .grid-row > .row .col{
    border-left: 1px solid var(--row-col-color);
  }
  /* [data-theme=dark-blue] .form-grid .grid-row:hover{
    background: var(--grid-row-hover-color);
  } */
  
  /* Heading Color */
  [data-theme=dark-blue] .head-title, .form-section .section-head, .form-dashboard-section .section-head, .comment-box .comment-input-header{
    color: var(--primary-color);
  }
  
  /* Lists */
  [data-theme=dark-blue] .list-subject a{
    text-decoration: none;
    transition: 0.3s;
  }
  [data-theme=dark-blue] .list-subject a:hover{
    color: var(--secondary-color);
  }
  
  
  /* Report Table */
  [data-theme=dark-blue] .datatable .dt-header .dt-cell--header .dt-cell__content{
    background-color: var(--datatable-color);
  }
  
  [data-theme=dark-blue] .btn{
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box);
    outline:none !important;
  
  }
  [data-theme=dark-blue] .btn.btn-sm.filter-button.btn-primary-light{
    color: var(--btn-default-class) !important;
    background-color: white!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
  }
  [data-theme=dark-blue] .btn.btn-sm.filter-button.btn-primary-light:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
  }
  [data-theme=dark-blue] .sidebar-action.show-tags a{
    color: var(--btn-default-class) !important;
  }
  [data-theme=dark-blue] .add-list-group-by.sidebar-action a{
    color: var(--btn-default-class) !important;
  }
  [data-theme=dark-blue] .filter-button.btn-primary-light{
    outline:none !important;
  }
  [data-theme=dark-blue] .standard-filter-section .input-with-feedback.form-control.input-xs{
    background-color: white !important;
  }
  
  [data-theme=dark-blue] .grid-row .data-row.row.editable-row input:not([type="checkbox"]){
  background-color: var(--grid-row-select-color)!important;
  }
  [data-theme=dark-blue] .ql-editor{
  background-color: var(--text-editor-bg-color)!important;
  }
  [data-theme=dark-blue] .document-link-badge{
  background-color: var(--text-editor-bg-color)!important;
  }
  [data-theme=dark-blue] .document-link use {
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .document-link button {
    border:1px solid var(--sidebar-icon-color)!important; 
  }
  [data-theme=dark-blue] .document-link button:hover {
    background-color:var(--sidebar-icon-color)!important; 
  }
  [data-theme=dark-blue] .document-link button:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
  }
  [data-theme=dark-blue] .count{
    background-color: var(--counter-color)!important;
  }
  
  [data-theme=dark-blue] .btn-secondary-dark{
    background-color: var(--button-secondary-dark-color)!important;
  }
  [data-theme=dark-blue] .btn.btn-xs.btn-secondary-dark.action-btn:active{
    border:1px solid var(--button-secondary-dark-color)!important;
    outline:none !important;
    box-shadow: none!important;
  }
  
  [data-theme=dark-blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  use{
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill {
   background-color: var(--sidebar-icon-color)!important;
   color: white;
  }   
  [data-theme=dark-blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill:active {
    border:2px solid var(--btn-default-class)!important;
    outline:none !important;
    box-shadow: none!important;
  }   
  [data-theme=dark-blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm .data-pill use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
  }
  [data-theme=dark-blue] .text-muted.btn.btn-default.icon-btn:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
  }
  [data-theme=dark-blue] .widget-body .link-item:hover .indicator-pill.no-margin.yellow{
    background: var(--card-pill-bg-hover-color) !important;
    color: var(--sidebar-font-selected-color)!important;
    fill:var(--sidebar-font-selected-color)!important;
    stroke: var(--sidebar-font-selected-color)!important;
    --icon-stroke: var(--sidebar-font-selected-color)!important;
  }
  
  [data-theme=dark-blue] .widget-head .widget-control .indicator-pill.green{
    background: var(--sidebar-icon-color)!important;
    color: white!important;
  }
  [data-theme=dark-blue] .widget-head .widget-control .indicator-pill.green::before{
    background: white!important;
  }
  
  [data-theme=dark-blue] .widget.shortcut-widget-box .widget-head .widget-control .indicator-pill.ellipsis.gray{
    background: var(--sidebar-icon-color)!important;
    color: var(--white-color)!important;
  }
  [data-theme=dark-blue] .widget.shortcut-widget-box .widget-head .widget-control .indicator-pill.ellipsis.gray::before{
    background: var(--white-color)!important;
  }
  [data-theme=dark-blue] .widget-head .widget-control .indicator-pill.grey{
    background: var(--sidebar-icon-color)!important;
    color: var(--white-color)!important;
  }
  [data-theme=dark-blue] .widget-head .widget-control .indicator-pill.grey::before{
    background: var(--white-color)!important;
  }
  [data-theme=dark-blue] .filter-chart.btn.btn-xs.pull-right{
    background: white!important;
    border: 1px solid var(--sidebar-icon-color)!important ;
  }
  [data-theme=dark-blue] .filter-chart.btn.btn-xs.pull-right use{ 
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
  }
  
  
  [data-theme=dark-blue] .filter-chart.btn.btn-xs.pull-right:hover{
    background: var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .filter-chart.btn.btn-xs.pull-right:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
  }
  [data-theme=dark-blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu {
    background: white!important;
    border: 1px solid var(--sidebar-icon-color)!important ;
  }
  [data-theme=dark-blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu use{
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu:hover {
    background: var(--sidebar-icon-color)!important;

  }
  [data-theme=dark-blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
  }
  
  [data-theme=dark-blue] .page-form .label-area{
    color:white!important;
  }
  
  
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .filter-selector .btn-group .btn.btn-sm.filter-button.btn-primary-light use{
    --icon-stroke:var(--list-view-page-header-icon)!important;
    stroke:var(--list-view-page-header-icon)!important;
  } 
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .filter-selector .btn-group .btn.btn-sm.filter-button.btn-primary-light:hover use {
    --icon-stroke:white!important;
  } 
  
  
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .filter-selector .btn-group .btn.btn-default.btn-sm.filter-x-button use{
    --icon-stroke:var(--list-view-page-header-icon)!important;
    stroke:var(--list-view-page-header-icon)!important;
  } 
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .filter-selector .btn-group .btn.btn-default.btn-sm.filter-x-button:hover use {
    --icon-stroke:white!important;
  } 
  
  
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .sort-selector .btn-group .btn.btn-default.btn-sm.btn-order use{
    --icon-stroke:var(--list-view-page-header-icon)!important;
    stroke:var(--list-view-page-header-icon)!important;
  } 
  [data-theme=dark-blue] .page-form.flex .filter-section.flex .sort-selector .btn-group .btn.btn-default.btn-sm.btn-order:hover use {
    --icon-stroke:white!important;
  } 
  
  [data-theme=dark-blue] .col-lg-2.layout-side-section .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm li {
    color: var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .col-lg-2.layout-side-section .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm button {
    border:1px solid var(--sidebar-icon-color)!important;
  }
  [data-theme=dark-blue] .col-lg-2.layout-side-section .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm button:hover {
    background-color:  var(--sidebar-icon-color)!important;
  }

 
  [data-theme=dark-blue] .input-group.search-bar.text-muted .awesomplete #awesomplete_list_1 span{
    color:black!important;
  }
  
  [data-theme=dark-blue] .dropdown-menu.dropdown-menu-right.show a{
    color:black!important
  }
  
  
  
  
  
  
  